<template>
<div class="w">
  <div class="crumbs">
    <router-link to="/">首页</router-link>
    <span>/</span>
    <router-link to="/expertList">专家支持</router-link>
    <span>/</span>
    详情
  </div>
  <div class="expert-info">
    <div class="expert-img">
      <img :src="info.imgurl">
    </div>
    <div class="expert-box">
      <div class="expert-intro">
        {{info.summary}}
      </div>
      <div class="expert-name">
        {{info.name}}
      </div>
    </div>
  </div>
  <div class="expert-detail">
    <div v-html="info.body"></div>
  </div>
</div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { yncyExpert } from '../api'

export default defineComponent({
  name: 'Expert',
  setup () {
    const route = useRoute()

    const info = ref({})
    const getExpertDetail = () => {
      yncyExpert({ id: route.query.id }).then(res => {
        info.value = res.result
      })
    }

    onMounted(() => {
      getExpertDetail()
    })

    return { info }
  }
})
</script>

<style lang="scss" scoped>
.expert{
  &-info{
    margin: 40px 0;
    display: flex;
  }
  &-img{
    width: 300px;
    margin-right: 40px;
    img{
      width: 100%;
    }
  }
  &-box{
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
  }
  &-name{
    font-size: 22px;
    margin-bottom: 20px;
  }
  &-intro{
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 30px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &-detail{
    margin-bottom: 90px;
    &>div{
      font-size: 16px;
      line-height: 20px;
    }
  }
}
</style>
